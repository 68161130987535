/*---

Basic link styles with support for other element types:

```
<p><a href="#0">Regular link</a></p>

<p><span class="a">Faux link</span></p>

<p><label class="a">Label link</label></p>
```

`subtle` to use inherited colour and `block` for block links:

```
<p><a href="#0" class="a--subtle">Subtle link</a></p>

<p><a href="#0" class="a--block">Block link</a></p>
```

*/

a,
.a {
	color: $c--brand-primary;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s ease;

	&:hover {
		text-decoration: none;
	}
}

a {
	&:focus {
		color: lighten($c--brand-primary, $amount: 10%)
	}

	&:link {
		-webkit-tap-highlight-color: rgba($c--white, 0);
	}
}

.a {
	&--block {
		display: block;
	}

	&--subtle {
		color: inherit;
		text-decoration: none;
	}
}
