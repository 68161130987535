.site-header {
	position: relative;
	z-index: z(site-header);

	&__placeholder {
		position: relative;
		top: 0;
		left: 50%;
		width: 100vw;
		margin-left: -50vw;
		max-width: 100%;
		z-index: 3;
		height: rem(80px);

		.site-header--light & {
			position: absolute;
		}

		@include mq($from: tablet) {
			height: rem(210px);
		}
	}

	&.menu-is-open .site-header__placeholder {
		height: 100vh;
	}

	&__fixed {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;

		.menu-is-open & {
			@include mq($to: tablet) {
				position: fixed;
				height: 100vh;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $c--brand-primary;
			}
		}
	}

	&__container {
		position: relative;
		line-height: 1;
		background-color: transparent;
		box-shadow: none;
		color: $c--brand-primary;
		height: inherit;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			will-change: opacity;
			transition: opacity 0.2;
			background-color: transparent;
			z-index: -1;
		}

		.site-header--light & {
			color: $c--white;
		}
	}

	&__inner {
		@include soft--ends($spacing-unit--x-small);

		@include mq($from: tablet) {
			@include soft--ends($spacing-unit--small);
		}

		@include mq($from: tablet-wide) {
			@include soft--ends($spacing-unit--medium);
		}

		.menu-is-open .container {
			@include mq($to: tablet) {
				margin-left: 0;
				margin-right: 0;
				color: $c--white;
			}
		}

		.menu-is-open & {
			@include mq($to: tablet) {
				height: 100%;
			}
		}
	}

	&__mobile {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.site-header__home {
			width: 20vw;
			height: 14.5vw;
		}

		@include mq($from: tablet) {
			display: none;
		}
	}

	&__nav {
		display: none;

		.menu-is-open & {
			@include mq($to: tablet) {
				display: flex;
				height: calc(100% - 85px);
				overflow: hidden;
			}
		}

		@include mq($from: tablet) {
			display: block;
			position: relative;
			width: 100%;
		}
	}

	&__logo {
		width: 100%;
		height: 100%;
		@include align;
		vertical-align: middle;
	}
}