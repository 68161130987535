.site-footer {
	position: relative;
	z-index: z(site-footer);
	overflow: hidden;
	background-color: $c--brand-primary;
	color: $c--brand-secondary;
	font-size: rem(18px);
	line-height: 1.5;

	@include mq($from: tablet) {
		margin-top: $spacing-unit--large;
	}

	&__top {
		background-color: $c--white;
	}
	&__inner {
		display: flex;
		flex-direction: column;
		@include soft--ends($spacing-unit--large);
		
		@include mq($from: tablet) {
			flex-direction: row;
			@include soft--ends($spacing-unit--large);
		}
		@include mq($from: tablet-wide) {
			@include soft--ends($spacing-unit--x-large);
		}
		&:nth-child(2) {
			@include mq($to: tablet) {
				order: -1;
			}
		}
	}
	&__section {
		display: flex;
		width: 100%;
		flex-shrink: 0;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-bottom: $spacing-unit;
		@include mq($from: tablet) {
			width: 33.3333%;
		}
	}
	&__social {
		p {
			color: $c--white;
		}
		.icon {
			display: block;
			width: 30px;
			height: 30px;
		}
	}
	&__legal {
		text-align: center;
		p {
			color: $c--brand-secondary;
			font-weight: 300;
		}
	}
	&__social,
	&__legal {
		a {
			backface-visibility: hidden;
			perspective: 1000;
			display: inline-block;
			position: relative;
			color: $c--white;
			font-family: $opensans-bold;
			font-weight: $w--bold;
			@include soft--sides($spacing-unit--x-small);
		}
	}
	@include mq($from: tablet) {
		text-align: right;
	}
	&__home {
		display: block;
		position: relative;
		fill: currentColor;
		width: rem(152px);
		height: rem(150px);

		@include mq($from: tablet-wide) {
			width: rem(200px);
			height: rem(150px);
		}
	}
	&__logo {
		width: 100%;
		height: 100%;
		@include align;
	}
}

.site-footer__top {
	display: flex;
}

.site-footer__left,
.site-footer__right {
	@include mq($from: tablet-wide) {
		width: 30%;
		position: relative;
	}
}

.site-footer__left:before,
.site-footer__right:before {

	@include mq($from: tablet-wide) {
		width: 200%;
		height: 200%;
		transform-origin: 50% 0;
		transition: transform 3s cubic-bezier(.755,.05,.855,.06);
		background: $c--brand-primary;
		z-index: 0;
		perspective: 1000;
		content: "";
		display: block;
		position: absolute;
	}
}

.site-footer__left {
	&::before {
		@include mq($from: tablet-wide) {
			top: 65%;
			left: 64%;
			transform: translate(-50%,0) rotateY(0) rotate(18deg);
		}
	}
}

.site-footer__right {
	&::before {
		@include mq($from: tablet-wide) {
			top: 65%;
			left: 36%;
			transform: translate(-50%,0) rotateY(0) rotate(-18deg);
		}
	}
}

.site-footer__cta {

	margin-left: auto;
	margin-right: auto;

	@include mq($from: tablet-wide) {
		width:40%;
		background-color: $c--white;
		text-align: center;
	}

	.button-wrapper {
		margin-bottom: $spacing-unit--large;

		@include mq($from: tablet-wide) {
			margin-bottom: rem(100px);
		}
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: $spacing-unit--small;
	}
}