.layout-width {
	&--60 {
		position: relative;
		width: 60%;
		margin-left: auto;
		margin-right: auto;

		@include mq($to: tablet) {
			width: calc(100% - ( #{$spacing-unit--small} * 2));
		}
	}
	&--80 {
		position: relative;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	&--85 {
		position: relative;
		width: 90%;
		margin-left: auto;
		margin-right: auto;

		@include mq($to: tablet) {
			width: calc(100% - ( #{$spacing-unit--x-small} * 2));
		}
	}

	&--90 {
		position: relative;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	&--95 {
		position: relative;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}

	&--100 {
		position: relative;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.layout {

	&--push-x-large {
		margin-top: $spacing-unit--x-large;
	}

	&--push-large {
		margin-top: $spacing-unit;
		
		@include mq($from: tablet) {
			margin-top: $spacing-unit--large;
		}
	}
	&--push-small {
		margin-top: $spacing-unit--small;
	}
}



@mixin layout-push($spacing, $modifier) {
	$modifier: if($modifier and $modifier != 'base', --#{$modifier}, '');

	@for $i from 1 through $grid-column-count {

		.layout-push#{$modifier} {
			margin-top: $spacing;
		}
	}
}

$spacing-list: (
	small:  $spacing-unit--small,
	medium: $spacing-unit--medium,
	large:  $spacing-unit--large,
	x-large: $spacing-unit--x-large,
);


@each $modifier, $spacing in $spacing-list {
	@include layout-push($spacing,$modifier);
}