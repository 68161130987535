/* ==========================================================================
   #BURGER - toggle menu button
   ========================================================================== */
.burger-menu-button {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 27px;
	height: 18px;
	outline: none;
	z-index: 10;

	&:before,
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: $c--white;
		position: absolute;
		left: 0;
		right: 0;
		opacity: 0;
		transform-origin: 50% 50%;
		transition: opacity .25s ease, transform .35s ease-in-out;
	}

	&:before {
		top: 0;
		transform: translateY(2px);
		opacity: 1;
	}

	&:after {
		transform: translateY(-2px);
		opacity: 1;
	}

	&__inner {
		position: relative;
		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		vertical-align: middle;
		content: "";
		background: no-repeat 50%;
		background-size: 100% 100%
	}

	&__inner:before,
	&__inner:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: $c--white;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -1px;
		transform-origin: 50% 50%;
		transform: scaleX(.8) translateX(-20%) rotate(0deg);
	}
}

.site-nav__toggle--branded .burger-menu-button:before,
.site-nav__toggle--branded .burger-menu-button:after,
.site-nav__toggle--branded .burger-menu-button__inner:before,
.site-nav__toggle--branded .burger-menu-button__inner:after {
	background: $c--brand-primary;
}

.menu-is-open .site-nav__toggle--branded .burger-menu-button:before,
.menu-is-open .site-nav__toggle--branded .burger-menu-button:after,
.menu-is-open .site-nav__toggle--branded .burger-menu-button__inner:before,
.menu-is-open .site-nav__toggle--branded .burger-menu-button__inner:after {
	background: $c--white;
}

.menu-is-open .burger-menu-button__inner:before {
	transform: scale(1) translateX(0) rotate(45deg);
}

.menu-is-open .burger-menu-button__inner:after {
	transform: scale(1) translateX(0) rotate(-45deg);
}

.menu-is-open .burger-menu-button:before,
.menu-is-open .burger-menu-button:after {
	opacity: 0;
}