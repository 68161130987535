.contact-section {
	position: relative;
}

.contact-header {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: $spacing-unit--large;

	@include mq($from: tablet-small) {
		flex-direction: row;
		justify-content: flex-start;
	}

	@include mq($from: tablet-wide) {
		margin-bottom: rem(100px);
	}

	&__copy,
	&__info {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 1 0 auto;
		z-index: 1;
	}

	&__title {
		font-size: rem(36px);
		font-family: $amaranth-bold;
		font-weight: 600;
		color: $c--brand-primary;
		margin-bottom: $spacing-unit--x-small;

		@include mq($from: tablet-wide) {
			font-size: rem(62px);
			margin-bottom: $spacing-unit;
		}
	}

	&__lead {
		font-size: rem(20px);
		font-family: $amaranth-regular;
		line-height: 1.4;
		color: $c--brand-secondary;
		margin-top: 0px;

		@include mq($from: tablet-small) {
			max-width: 90%;
		}

		@include mq($from: tablet-wide) {
			max-width: 70%;
		}

	}
}

.contact-info {

	&__box {
		display: flex;
		flex: 1 0 auto;
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		padding-top: $spacing-unit--large;

		@include mq($to: phone) {
			.button {
				width: 100%;
				font-size: rem(18px);
			}
		}
	}

	&__label {
		font-size: rem(14px);
		color: lighten($c--brand-primary, 5%);
		line-height: rem(26px);
		font-family: $opensans-regular;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__link {
		font-size: rem(26px);
		line-height: rem(28px);
		font-family: $amaranth-regular;
		color: $c--brand-primary;
		font-weight: 600;
	}

	@include mq($from: tablet-wide) {

		&__label {
			font-size: rem(14px);
			line-height: rem(24px);
		}

		&__link {
			font-size: rem(30px);
			line-height: rem(34px);
		}
	}
}

.contact-card {
	position: relative;
	padding-right: $spacing-unit;
	margin-bottom: $spacing-unit;

	&__align {
		height: 100%;
		width: 100%;
		display: block;
		@include soft--sides($spacing-unit--small);
		@include soft--ends($spacing-unit);
	}

	p {
		color: $c--black;
		font-family: $amaranth-regular;
		font-size: rem(28px);
		text-align: center;
		font-weight: 600;
		letter-spacing: 1.5px;
	}

	span {
		color: $c--black;
		display: inline-block;
		font-family: $opensans-regular;
		font-size: rem(16px);
		text-align: center;
		font-weight: 400;
		letter-spacing: 1px;
		overflow: hidden;
	}
}

.contact-image__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.08);
}


.glide__slide:nth-child(4n+1) .contact-card .placeholder:before {
	padding-top: 133%;
}

.glide__slide:nth-child(4n+2) .contact-card .placeholder:before {
	padding-top: 120%;
}

.glide__slide .placeholder {
	box-shadow: 11px 10px 37px -14px rgba(0, 0, 0, 0.75);
}

.glide__slide:nth-child(4n+3) .contact-card .placeholder:before {
	padding-top: 110%;
}

.glide__slide:nth-child(4n+4) .contact-card .placeholder:before {
	padding-top: 100%;
}