/* ==========================================================================
   #FORMS
   ========================================================================== */

   .c-form {

	position: relative;
	z-index: 1;
	// background-color: $c-lightblue;
	padding: $spacing-unit--small;
	// box-shadow: 0 calc(8px / 2) 8px rgba(0,0,0,.05);
	margin-bottom: 70px;

	@include mq($from: tablet-wide) {
		padding: $spacing-unit--large;
	}
	
	&__block:not(:last-child) {
		margin-bottom: $spacing-unit;

		@include mq($from: tablet-wide) {
			margin-bottom: $spacing-unit--large;
		}
	}

	&__block {

		&.is-hidden {
			height: 0;
		}
	}

	&__group {
		@include fp(margin-bottom,12,18);

		&.is-hidden {
			display: none;
		}
	}

	&__label {
		display: block;
		font-family: $opensans-regular;
		font-weight: 600;
		font-style: normal;
		@include fp(font-size,16,18);
		line-height: 1em;
		transform: translateY(0em);
		transition: transform .2s ease-in-out,font-size .2s ease-in-out;
		position: absolute;
		z-index: 1;
		pointer-events: none;
		color: $c--brand-primary;

		&.is-focused {
			font-size: rem(13px);
			transform: translateY(0);
		}
	}

	&__field {
		position: relative;
		&:before{
			position: absolute;
			content: "";
			background-color: $c--brand-primary;
			bottom: -2px;
			height: 2px;
			left: 0;
			transform: scaleX(0);
			transform-origin: bottom left;
			transition: transform .4s ease-out;
			width: 100%;
		}
	}

	&__textarea {
		overflow-scrolling: touch;
	    background: linear-gradient(to top,rgba(224,31,38,.15) 0,transparent 1px);
	    background-position: 0 1.5em;
	    background-size: 100% 1.5em;
	    font-size: 16px;
	    height: 150px;
	    line-height: 1.5;
	    margin-top: $spacing-unit;
	    overflow: auto;
	    resize: none;
		vertical-align: top;
		border: 0px;

	    &:focus {
	    	background: linear-gradient(to top,transparent 0,transparent 1px);
	    }
	}
}

.is-focused {

	&.c-form__label {
		font-size: rem(12px);
		transform: translateY(0);
		opacity: 0.5;
	}
	
	&.c-form__field:before {
		transform: none;
	}
}

.parsley-custom-error-message {
	font-size: rem(10px);
	font-family: $opensans-regular,sans-serif;
	color: $c--brand-primary;
	text-align: left;
}




