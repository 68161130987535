/*---

Available icons:

```
<ul class="sg-icons">
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#check"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#cross"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#link"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#mail"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#minus"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#phone"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#plus"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-up"></use></svg></li>
</ul>
```

Use `inline` when used with text:

```
<p><svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg> Lorem ipsum dolor sit amet, consectetur <svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg> adipisicing elit. <svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></p>
```

Use `icon-text` for vertical alignment:

```
<span class="icon-text">
	<span>Icon text, description or title</span>

	<svg class="icon">
		<use xlink:href="/assets/img/sprite.svg#plus"></use>
	</svg>
</span>
```

```
<span class="icon-text">
	<svg class="icon">
		<use xlink:href="/assets/img/sprite.svg#plus"></use>
	</svg>

	<span>Icon text, description or title</span>
</span>
```

*/

$icon-size: 1em;
$icon-size-large: 3.5em;

.icon {
	display: inline-block;
	width: $icon-size;
	height: $icon-size;
	fill: currentColor;
	vertical-align: top;

	&--inline {
		// y = line-height measurment was taken at
		// x =  how far off centre the baseline is, at `y`
		$x: 3;
		$y: 24;
		vertical-align: n($x/$y * 100%);
	}

	&--large {
		width: $icon-size-large;
		height: $icon-size-large;
		margin-bottom: 1em;
	}

}

.icon-text {
	display: flex;
	align-items: center;

	> * {
		margin-left: $spacing-unit--x-small;

		&:first-child {
			margin-left: 0;
		}
	}

	&--vertical {
		flex-direction: column;
	}


}
