.site-nav {
	&__links {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.menu-is-open & {
			@include mq($to: tablet) {
				flex-direction: column;
				height: 100%;
				justify-content: center;
				align-items: flex-start;
				margin-left: $spacing-unit--large;
				color: $c--white;
			}
		}
	}

	&__item {
		display: inline-block;
		font-family: $amaranth-bold;
		font-weight: 600;
		font-size: rem(36px);
		margin-bottom: $spacing-unit--medium;

		@include mq($from: phone) {
			font-size: rem(40px);
		}

		@include mq($from: tablet) {
			font-family: $amaranth-regular;
			font-size: rem(18px);
			font-weight: 400;
			margin-bottom: 0;
		}

		a {
			display: block;
			position: relative;
			backface-visibility: hidden;
			perspective: 1000;
			color: inherit;
			transition: color 0.2s;
		}

		.site-header__home {
			display: block;
			position: relative;
			fill: currentColor;
			width: 11.5vw;
			height: 10.5vw;

		}

		&--logo {
			width: 100%;
			height: 100%;
			@include vh;

			@include mq($from: tablet) {
				@include vh-reset;
			}
		}
	}

	&__toggle {
		position: relative;
		width: 40px;
		height: 40px;
		transition: all 0.3s ease;
		cursor: pointer;

	}
}