.video-gallery {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 0;
	flex-shrink: 0;
	justify-content: flex-start;
	align-items: normal;
	margin-left: -$spacing-unit--small;
	margin-right: -$spacing-unit--small;

	&__item {
		position: relative;
		min-height: 1px;
		position: relative;
		width: 100%;
		flex: 0 0 100%;
		max-width: 100%;
		left: auto;
		right: auto;
		padding-left: $spacing-unit--x-small;
		padding-right: $spacing-unit--x-small;
		margin-bottom: $spacing-unit;

		@include mq($from: tablet) {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: $spacing-unit--x-small;
			padding-right: $spacing-unit--x-small;
		}

		.placeholder {
			box-shadow: 11px 10px 37px -14px rgba(0, 0, 0, 0.55);
		}

		@include mq($from: tablet-wide) {
			flex: 0 0 33.3333%;
			max-width: 33.3333%;
			padding-left: $spacing-unit--x-small;
			padding-right: $spacing-unit--x-small;
		}
	}

	&__poster {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		img {
			height: 48px;
			margin-left: -24px;
			margin-top: -24px;
			opacity: 0.8;
			width: 48px;
			left: 50%;
			margin-left: -10px;
			margin-top: -10px;
			opacity: 1;
			position: absolute;
			top: 50%;
			transition: opacity 0.3s ease 0s;
		}
	}
}