/*
	Box sizing
*/

* {

	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}

/*
	Basic document styling
*/

html {
	font-size: $html-font-size;
	min-height: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: none;
	scroll-behavior: smooth;
}

body {
	background-color: $c--body-bg;
	color: $c--body-colour;
	font-family: $stack;
	font-size: $p;
	line-height: $lh-base;
	@include antialias;

	// .menu-is-open {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// }
}

.site-wrapper {
	@include hard--ends;
	overflow: hidden;
}


/*
	Text selection/highlighting
*/

@include selection {
	background: $c--brand-primary;
	color: $c--white;
	text-shadow: none;

}


/*
	Vue
*/

[v-cloak] {
	display: none;
}