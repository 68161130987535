.portfolio {
	&__nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	&__link {
		margin-right: $spacing-unit;
		font-size: rem(18px);
		font-display: $amaranth-regular;
		font-weight: 300;
		line-height: rem(22px);
		@include soft--ends($spacing-unit--x-small);

		@include mq($from: tablet) {
			font-size: rem(20px);
			@include soft--ends($spacing-unit--small);
		}
	}

	&__grid-item {
		overflow: hidden;

		.placeholder--landscape img {
			height: 100%;
			max-width: none;
		}
	}
}