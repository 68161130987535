
.services-list {

    &__item {
        margin-bottom: $spacing-unit--medium;
        
        @include mq($to: tablet) {
            text-align: center;
        }

        a {
            outline: none;
            display: block;
        }

        .icon-text span {
            @include fp(font-size,18,22);
            font-family: $amaranth-regular;
        }
    }
}