// HOMEPAGE SLIDER

.slider {
	position: relative;
}

.slider__inner {
	position: relative;
	background-image: url('../img/background/homepage.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: auto;
	padding-bottom: 120%;
	margin-bottom: $spacing-unit;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,0.6);
		z-index: 0;
	}
	@include mq($from: phone-wide) {
		margin-bottom: $spacing-unit--large;
	}
	@include mq($from: phablet) {
		padding-bottom: 61%;
		margin-bottom: $spacing-unit;
	}
	@include mq($from: tablet-wide) {
		height: auto;
		padding-bottom: 45%;
		margin-bottom: 10%;
	}

	@include mq($from: desktop) {
		margin-bottom: 5%;
	}
}

.slider__wrapper {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	top: 30%;

	@include mq($from: tablet-wide) {
		top: 40%;
	}
}

.slide {
	position: relative;
	overflow: hidden;
    width: 100%;
	padding-top: 100%;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		max-width: none;
		min-width: 100%;
		min-height: 100%;
	}

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}
