fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

input,
select,
textarea {
	appearance: none;
	background-clip: border-box;
	margin: 0;
	outline: 0;
	text-align: left;
	vertical-align: top;
	width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
	width: auto;
	height: auto;
}

input[type='checkbox'] {
	appearance: checkbox;
}

input[type='radio'] {
	appearance: radio;
}

textarea,
select[size],
select[multiple] {
	height: auto;
}

// remove dropdown for IE10+
select::-ms-expand {
	display: none;
}

// remove IE11 select element selected option state
select:focus::-ms-value {
	background-color: transparent;
	color: inherit;
}

// Allow only vertical resizing of textareas
textarea {
	resize: vertical;
}

// remove search controls
input[type='search']::-webkit-search-decoration {
	display: none;
}


// button
button {
	appearance: none;
	background: none;
	border: none;
	border-radius: 0;
	margin: 0;
	padding: 0;
}

button,
.button {
	cursor: pointer;
	display: inline-block;
	font: normal 16px/1 sans-serif;
	outline: 0;
	overflow: visible;
	text-align: center;
	text-decoration: none;
	vertical-align: top;
	width: auto;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

// disabled states
[disabled],
[disabled] * {
	box-shadow: none;
	cursor: not-allowed;
	user-select: none;
}


input[type="date"], 
input[type="url"], 
input[type="text"], 
input[type="password"], 
input[type="search"], 
input[type="email"], 
input[type="tel"], 
input[type="number"] {
    
    height: 32px;
}

input[type="date"], 
input[type="url"], 
input[type="text"], 
input[type="password"], 
input[type="search"], 
input[type="email"], 
input[type="tel"], 
input[type="number"], 
textarea {
    font-family: $opensans-regular;
    font-weight: 600;    
    font-style: normal;
    margin: 15px 0 0;
    width: 100%;
    padding: 0 0 $spacing-unit--x-small;
    border: 0px;
    border-bottom: 1px solid $c--grey;
    transition: border .2s ease;
    letter-spacing: -.01em;
    color: $c--grey-dark;
    background-color: transparent;
    appearance: none;
}